<template>
  <div class="page inPage PopupPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">Popup</div>
    </div>
    <div class="pageBody">
      <lee-popup v-model="show1" style="padding: 64px">
        <h1>认知能力是一个人最核心的竞争力</h1>
        <p>

        </p>
      </lee-popup>
      <lee-popup v-model="show2" style="height: 30%" :position="position2"/>
      <lee-popup v-model="show3" style="width: 40%;height: 100%;" :position="position3"/>
      <lee-popup
          v-model="show4"
          closeable
          position="bottom"
          :style="{ height: '30%' }"
      />
      <!-- 自定义图标 -->
      <lee-popup
          v-model="show5"
          closeable
          close-icon="hide"
          position="bottom"
          :style="{ height: '30%' }"
      />
      <!-- 图标位置 -->
      <lee-popup
          v-model="show6"
          closeable
          close-icon-position="top-left"
          position="bottom"
          :style="{ height: '30%' }"
      />
      <!-- 圆角弹窗（居中） -->
      <lee-popup v-model="show7" round :style="{ padding: '64px' }"/>
      <!-- 圆角弹窗（底部） -->
      <lee-popup
          v-model="show8"
          round
          position="bottom"
          :style="{ height: '30%' }"
      />
      <lee-popup
          v-model="show9"
          position="bottom"
          :style="{ height: '30%' }"
          @onOpen="showToast('打开')"
          @onClose="showToast('关闭')"
      />
      <lee-cell-group title="基础用法" inset>
        <lee-cell title="展示弹出层" is-link @click="handShow1"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="弹出位置" inset>
        <ul>
          <li class="clickable" @click="handShow2('top')">
            <div class="li">
              <lee-icon name="arrow-up"/>
              <div class="text">顶部弹出</div>
            </div>
          </li>
          <li class="clickable" @click="handShow2('bottom')">
            <div class="li">
              <lee-icon name="arrow-down"/>
              <div class="text">底部弹出</div>
            </div>
          </li>
          <li class="clickable" @click="handShow3('left')">
            <div class="li">
              <lee-icon name="arrow-left"/>
              <div class="text">左侧弹出</div>
            </div>
          </li>
          <li class="clickable" @click="handShow3('right')">
            <div class="li">
              <lee-icon name="arrow-right"/>
              <div class="text">右侧弹出</div>
            </div>
          </li>
        </ul>
      </lee-cell-group>
      <lee-cell-group title="关闭图标" inset>
        <lee-cell title="关闭图标" is-link @click="handShow4()"></lee-cell>
        <lee-cell title="自定义图标" is-link @click="handShow5()"></lee-cell>
        <lee-cell title="图标位置" is-link @click="handShow6()"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="圆角弹窗" inset>
        <lee-cell title="圆角弹窗（居中）" is-link @click="handShow7()"></lee-cell>
        <lee-cell title="圆角弹窗（底部）" is-link @click="handShow8()"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="事件监听" inset>
        <lee-cell title="监听事件" is-link @click="handShow9()"></lee-cell>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import {showToast} from '@/leeplus';
const router = useRouter()
const back = () => {
    router.push('/')
}

const show1 = ref(false)
const show2 = ref(false)
const show3 = ref(false)
const show4 = ref(false)
const show5 = ref(false)
const show6 = ref(false)
const show7 = ref(false)
const show8 = ref(false)
const show9 = ref(false)
const position2 = ref('')
const position3 = ref('')
const handShow1 = () => {
    show1.value = true
}
const handShow2 = (pos) => {
    show2.value = true
    position2.value = pos
}
const handShow3 = (pos) => {
    show3.value = true
    position3.value = pos
}
const handShow4 = () => {
    show4.value = true
}
const handShow5 = () => {
    show5.value = true
}
const handShow6 = () => {
    show6.value = true
}
const handShow7 = () => {
    show7.value = true
}
const handShow8 = () => {
    show8.value = true
}
const handShow9 = () => {
    show9.value = true
}


</script>
<style>
.PopupPage ul {
  display: flex;
  background: var(--lee-white);
}

.PopupPage ul li {
  width: 25%;
}

.PopupPage ul li .li {
  padding: 50% 0;
  height: 0;
  border-right: solid 1px var(--lee-gray-light);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.PopupPage ul li .li i {
  font-size: 28px;
}

.PopupPage ul .text {
  font-size: 12px;
  margin-top: 8px;
}


</style>
